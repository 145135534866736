export default {
    '推荐任务':"推荐任务",
    '查看更多':"查看更多",
    '人气排行':"人气排行",
    '热门推荐':"热门推荐",
    '播放':"播放",
    '获取中......':"获取中......",
    '刷新成功':"刷新成功",
    '登录':"登录",
    '请输入用户名':"请输入用户名",
    '忘记密码':"忘记密码",
    '没有账户？马上注册':"没有账户？马上注册",
    '请输入密码':"请输入密码",
    '注册':"注册",
    '请输入邀请码':"请输入邀请码",
    '请输入用户名(6-12个英文字母或数字)':"请输入用户名(6-12个英文字母或数字)",
    '请输入登录密码(6-12个英文字母或数字)':"请输入登录密码(6-12个英文字母或数字)",
    '我已经知晓并同意"开户协议"各项条约':"我已经知晓并同意\"开户协议\"各项条约",
    '请勾选下方开户协议':"请勾选下方开户协议",
    '玩法规则':"玩法规则",
    '玩法提示':"玩法提示",
    '任务记录':"任务记录",
    '从可选和值形态里面选择号码进行下注':"从可选和值形态里面选择号码进行下注",
    '中奖说明':"中奖说明",
    '三个开奖号码总和值11~18 为大;总和值3~ 10为小':"三个开奖号码总和值11~18 为甜;总和值3~ 10为言",
    '投注范例':"投注范例",
    '投注方案：小 开奖号码：123,即中小':"投注方案：言 开奖号码：123,即中言",
    '任务单':"任务单",
    '可用余额':"可用余额",
    '元':"元",
    '提交':"提交",
    '当前选号':"当前选号",
    '每注金额':"每注金额",
    '请输入金额':"请输入金额",
    '总共':"总共",
    '注':"注",
    '合计':"合计",
    '清空订单':"清空订单",
    '确认提交':"确认提交",
    '期号':"期号",
    '开奖号码':"开奖号码",
    '大':"甜",
    '小':"言",
    '单':"蜜",
    '双':"语",
    '金额错误':"金额错误",
    '请选号':"请选号",
    '请填写金额':"请填写金额",
    '余额不足，请联系客服充值':"余额不足，请联系客服充值",
    '未选中':"未选中",
    '请联系管理员领取该任务':"请联系管理员领取该任务",
    '开奖成功，期号':"开奖成功，期号",
    '填写收款卡':"填写收款卡",
    '请输入您的收款卡信息':"请输入您的收款卡信息",
    '银行卡号':"银行卡号",
    '请输入真实银行卡号':"请输入真实银行卡号",
    '银行名称':"银行名称",
    '请选择银行':"请选择银行",
    '尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款':"尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款",
    '确认绑卡':"确认绑卡",
    '请输入银行卡号':"请输入银行卡号",
    '数据为空':"数据为空",
    '下注金额':"下注金额",
    '金额':"金额",
    '任务类型':"任务类型",
    '下单时间':"下单时间",
    '结算时间':"结算时间",
    '充值':"充值",
    '提现':"提现",
    '我的钱包':"我的钱包",
    '详情':"详情",
    '余额(元)':"余额(元)",
    '个人报表':"个人报表",
    '账户明细':"账户明细",
    '个人中心':"个人中心",
    '信息公告':"信息公告",
    '在线客服':"在线客服",
    '请完成任务单后进入':"请完成任务单后进入",
    '请联系客服充值':"请联系客服充值",
    '功能已禁用':"功能已禁用",
    '账号下线':"账号下线",
    '登录/注册':"登录/注册",
    '登录可享受更多服务':"登录可享受更多服务",
    '头像':"头像",
    '选择头像':"选择头像",
    '确定':"确定",
    '真实姓名':"真实姓名",
    '性别':"性别",
    '绑定信息':"绑定信息",
    '已绑定':"已绑定",
    '无':"无",
    '男':"男",
    '女':"女",
    '未知':"未知",
    '盈利金额(元)':"盈利金额(元)",
    '盈利计算公式 : 中奖金额 - 任务金额':"盈利计算公式 : 中奖金额 - 任务金额",
    '任务金额':"任务金额",
    '充值金额':"充值金额",
    '提现金额':"提现金额",
    '中奖金额':"中奖金额",
    '联系':"联系",
    '全天7 * 24小时竭诚为您服务':"全天7 * 24小时竭诚为您服务",
    '收款卡信息':"收款卡信息",
    '添加收款卡':"添加收款卡",
    '提示:请绑定大型商业银行,如需修改,请您联系在线客服':"提示:请绑定大型商业银行,如需修改,请您联系在线客服",
    '请设置姓名后再绑定银行卡':"请设置姓名后再绑定银行卡",
    '请设置提现密码后再绑定银行卡':"请设置提现密码后再绑定银行卡",
    '修改登录密码':"修改登录密码",
    '保存':"保存",
    '旧密码':"旧密码",
    '请输入您的旧密码':"请输入您的旧密码",
    '新密码':"新密码",
    '请输入您的新密码':"请输入您的新密码",
    '请填写完整':"请填写完整",
    '两次密码输入不一致':"两次密码输入不一致",
    '修改真实姓名':"修改真实姓名",
    '请输入真实姓名':"请输入真实姓名",
    '为了您账户安全,真实姓名需要与绑定银行卡姓名一致':"为了您账户安全,真实姓名需要与绑定银行卡姓名一致",
    '设置资金密码':"设置资金密码",
    '请输入资金密码':"请输入资金密码",
    '请再次输入资金密码':"请再次输入资金密码",
    '性别修改':"性别修改",
    '设置':"设置",
    '基本信息设置':"基本信息设置",
    '基本信息':"基本信息",
    '登录密码':"登录密码",
    '资金密码':"资金密码",
    '退出登录':"退出登录",
    '提现密码已设置，需要修改请联系客服':"提现密码已设置，需要修改请联系客服",
    '已设置':"已设置",
    '未设置':"未设置",
    '提现中心':"提现中心",
    '提现金额 (元)':"提现金额 (元)",
    '全部':"全部",
    '到账时间：一般到账时间在5分钟左右，最快2分钟内到账':"到账时间：一般到账时间在5分钟左右，最快2分钟内到账",
    '限额说明':"限额说明",
    '余额':"余额",
    '马上提现':"马上提现",
    '请填写正确的金额':"请填写正确的金额",
    '提现记录':"提现记录",
    '说明':"说明",
    '提交时间':"提交时间",
    '审核时间':"审核时间",
    '视频影院':"视频影院",
    '没有更多了':"没有更多了",
    '次播放':"次播放",
    '请充值后观看视频':"请充值后观看视频",
    '预约大厅':"预约大厅",
    '首页':"首页",
    '预约':"预约",
    '视频':"视频",
    '我的':"我的",
    '空降':"空降",
    '约炮':"约炮",
    '同城':"同城",
    '空降活动':"空降活动",
    '约炮活动':"约炮活动",
    '同城活动':"同城活动",
    '制服':"制服",
    '国产':"国产",
    '最新':"最新",
    '最热':"最热",
    '推荐':"推荐",
    '亚洲':"亚洲",
    '直播':"直播",
    '偷拍':"偷拍",
    '欧美':"欧美",
    '动漫':"动漫",
    '测试':"测试",
    '姓名':"姓名",
    '系统公告':"系统公告",
    '提现金额(元)':'提现金额(元)'
}
